import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {Trans} from '@common/i18n/trans';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import {useDialogContext} from '@common/ui/overlays/dialog/dialog-context';
import {useForm} from 'react-hook-form';
import {Form} from '@common/ui/forms/form';
import {DialogFooter} from '@common/ui/overlays/dialog/dialog-footer';
import {Button} from '@common/ui/buttons/button';
import { TeamPayload, useCreateTeam } from '@app_modules/requests/use-create-team';
import { CrupdateTeamFields } from './crupdate-team-fields';

export function CreateTeamDialog() {
  const {formId, close} = useDialogContext();
  const form = useForm<TeamPayload>();
  const createTeam = useCreateTeam(form);
  return (
    <Dialog>
      <DialogHeader>
        <Trans message="New Team" />
      </DialogHeader>
      <DialogBody>
        <Form
          id={formId}
          form={form}
          onSubmit={value => {
            createTeam.mutate(value, {
              onSuccess: () => close(),
            });
          }}
        >
          <CrupdateTeamFields />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => close()}>
          <Trans message="Cancel" />
        </Button>
        <Button
          form={formId}
          type="submit"
          variant="flat"
          color="primary"
          disabled={createTeam.isPending}
        >
          <Trans message="Create" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
