import {apiClient, queryClient} from '@common/http/query-client';
import {useQuery} from '@tanstack/react-query';
import {PolicyFailReason} from '@common/billing/upgrade/policy-fail-reason';

interface ResourceStatus {
  allowed: boolean;
  failReason?: PolicyFailReason;
}

interface ResourceUsage {
  used: number;
  total: number;
  pending: number;
}


interface Response {
  billing: {
    used: number;
    pending: number;
    total: number;
    available: number;
  }
  
}

export function useBillingUsage() {
  return useQuery({
    queryKey: ['billing-usage'],
    queryFn: () => fetchBillingUsage(),
  });
}

export function reloadBillingUsage() {
  return queryClient.invalidateQueries({
    queryKey: ['billing-usage'],
  });
}

function fetchBillingUsage() {
  return apiClient
    .get<Response>(`billing/usage`)
    .then(response => response.data);
}
