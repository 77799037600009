import React, {Fragment, useState} from 'react';
import {DataTablePage} from '@common/datatable/page/data-table-page';
import {IconButton} from '@common/ui/buttons/icon-button';
import {EditIcon} from '@common/icons/material/Edit';
import {FormattedDate} from '@common/i18n/formatted-date';
import {ColumnConfig} from '@common/datatable/column-config';
import {Trans} from '@common/i18n/trans';
import {DataTableEmptyStateMessage} from '@common/datatable/page/data-table-emty-state-message';
import softwareEngineerSvg from '@common/admin/tags/software-engineer.svg';
import {DataTableAddItemButton} from '@common/datatable/data-table-add-item-button';
import {DeleteIcon} from '@common/icons/material/Delete';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { CreateTeamDialog } from './create-team-dialog';
import { UpdateTeamDialog } from './update-team-dialog';
import {reloadAccountUsage} from '@app/editor/use-account-usage';
import { Menu, MenuItem, MenuTrigger } from '@common/ui/navigation/menu/menu-trigger';
import {Button, ButtonProps} from '@common/ui/buttons/button';
import { PersonAddIcon } from '@common/icons/material/PersonAdd';
import { KeyboardArrowDownIcon } from '@common/icons/material/KeyboardArrowDown';
import { useAuth } from '@common/auth/use-auth';
import {openDialog} from '@common/ui/overlays/store/dialog-store';
import { ExitToAppIcon } from '@common/icons/material/ExitToApp';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { RenameTeamDialog } from './rename-team-dialog';
import { Team } from './team';
import { TeamMembersDialog } from '@app_modules/requests/team-members-dialog';
import { useRemoveMember } from '@app_modules/requests/remove-member';
import { PersonalTeam } from './user-teams';
import { useDeleteTeam } from '@app_modules/requests/delete-team';
import { LeaveTeamConfirmation } from './leave-team-confirmation';
import { reloadBillingUsage } from '@app_modules/requests/use-billing-usage';

export function TeamsIndexPage() {
  const navigate = useNavigate();
  const timezone = useUserTimezone();
  
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);

  const columnConfig: ColumnConfig<Team>[] = [
    {
      key: 'id',
      allowsSorting: false,
      maxWidth: 'max-w-50',
      header: () => <Trans message="ID #" />,
      body:  team => team.id,
    },
    
    {
      key: 'name',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Team Name" />,
      body:  team => team.name,
    },
    {
      key: 'members_count',
      allowsSorting: false,
      maxWidth: 'max-w-150',
      header: () => <Trans message="Team Member" />,
      body: team => team.members_count + ' members',
    },
    {
      key: 'actions',
      header: () => <Trans message="Manage" />,
      visibleInMode: 'all',
      hideHeader: true,
      align: 'end',
      maxWidth: 'max-w-130',
      body:  team => {
        return (
          <Fragment>
            <ManageButton
              setSelectorIsOpen={setSelectorIsOpen}
              team={team}
              onChange={reloadBillingUsage}
            />
          </Fragment>
        );
        
      },
    },
  ];

  return (
    <DataTablePage
      endpoint="teams"
      title={<Trans message="Teams
" />}
      columns={columnConfig}
      actions={<Actions />}
      enableSelection={false}
      //filters={TeamsIndexPageFilters}
      // onRowAction={item => {
      //   navigate(`/reports/${item.report_id}/view`);
      // }}
      emptyStateMessage={
        <DataTableEmptyStateMessage
          image={softwareEngineerSvg}
          title={<Trans message="No Teams have been created yet" />}
          filteringTitle={<Trans message="No matching Teams" />}
        />
      }
    />
  );
}

// interface DeleteTeamButtonProps {
//   team: Team;
// }
// function DeleteTeamButton({ team}: DeleteTeamButtonProps) {
//   const deleteTeam = useDeleteTeam();
//   return (
//     <DialogTrigger
//       type="modal"
//       onClose={confirmed => {
//         if (confirmed) {
//           deleteTeam.mutate({ id:  team.id});
//         }
//       }}
//     >
//       <Tooltip label={<Trans message="Delete Team Transaction" />}>
//         <IconButton
//           size="md"
//           className="text-muted"
//           disabled={deleteTeam.isPending}
//         >
//           <DeleteIcon />
//         </IconButton>
//       </Tooltip>
//       <ConfirmationDialog
//         title={<Trans message="Delete team" />}
//         body={<Trans message="Are you sure you want to delete this Team Transaction?" />}
//         confirm={<Trans message="Delete" />}
//       />
//     </DialogTrigger>
//   );
// }


function Actions() {
  return (
    <DialogTrigger type="modal">
      <DataTableAddItemButton>
        <Trans message="New Team" />
      </DataTableAddItemButton>
      <CreateTeamDialog />
    </DialogTrigger>
  );
}


interface ManageButtonProps {
  setSelectorIsOpen: (value: boolean) => void;
  team: Team;
  onChange?: (id: number) => void;
}
function ManageButton({
  setSelectorIsOpen,
  team,
  onChange,
}: ManageButtonProps) {
  const {user} = useAuth();

  return (
    <MenuTrigger onItemSelected={() => setSelectorIsOpen(false)}>
      <Button
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        color="primary"
        size="xs"
        variant="outline"
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Trans message="Manage" />
      </Button>
      <Menu>
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            openDialog(TeamMembersDialog, {team});
          }}
          value="teamMembers"
          startIcon={<PersonAddIcon />}
        >
          <Trans message="Members" />
        </MenuItem>
        {team.owner_id === user?.id && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              openDialog(RenameTeamDialog, {team});
            }}
            value="updateTeam"
            startIcon={<EditIcon />}
          >
            <Trans message="Rename" />
          </MenuItem>
        )}
        {team.owner_id !== user?.id && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              <UpdateTeamDialog team={team} />
              // openDialog(UpdateTeamDialog, {team, onChange});
            }}
            value="leaveTeam"
            startIcon={<ExitToAppIcon />}
          >
            <Trans message="Leave" />
          </MenuItem>
        )}
        {team.owner_id === user?.id && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              openDialog(DeleteTeamConfirmation, {team, onChange});
            }}
            value="deleteTeam"
            startIcon={<DeleteIcon />}
          >
            <Trans message="Delete" />
          </MenuItem>
        )}
        
      </Menu>
    </MenuTrigger>
  );
}

interface LeaveTeamDialogProps {
  team: Team;
  onChange?: (id: number) => void;
}
function LeaveTeamDialog({
  team,
  onChange,
}: LeaveTeamDialogProps) {
  const removeMember = useRemoveMember();
  const {user} = useAuth();
  const {close} = useDialogContext();
  return (
    <LeaveTeamConfirmation
      isLoading={removeMember.isPending}
      onConfirm={() => {
        removeMember.mutate(
          {
            teamId: team.id,
            memberId: user!.id,
            memberType: 'member',
          },
          {
            onSuccess: () => {
              close();
              onChange?.(PersonalTeam.id);
            },
          },
        );
      }}
    />
  );
}


interface DeleteTeamConfirmationProps {
  team: Team;
  onChange?: (id: number) => void;
}
function DeleteTeamConfirmation({
  team,
  onChange,
}: DeleteTeamConfirmationProps) {
  const deleteTeam = useDeleteTeam();
  const {close} = useDialogContext();
  return (
    <ConfirmationDialog
      isDanger
      title={<Trans message="Delete team" />}
      body={
        <Trans
          message="Are you sure you want to delete “:name“?"
          values={{name: team.name}}
        />
      }
      confirm={<Trans message="Delete" />}
      isLoading={deleteTeam.isPending}
      onConfirm={() => {
        deleteTeam.mutate(
          {id: team.id},
          {
            onSuccess: () => {
              close();
              onChange?.(PersonalTeam.id);
            },
          },
        );
      }}
    />
  );
}