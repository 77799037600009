import {useForm} from 'react-hook-form';
import {Trans} from '@common/i18n/trans';

import { MakePaymentLayout } from './make-payment-layout';
import { MakePaymentForm } from './make-payment-form';
import { useCreateMakePayment } from '@app_modules/requests/use-create-make-payment';
import { MakePayment } from './make-payment';
import { useState } from 'react';


export function CreateMakePaymentPage() {
  const form = useForm<MakePayment>({
    defaultValues: {
    },
  });
  const createDocumentRequest = useCreateMakePayment(form);
  const [agree, setAgree] = useState(false);
  
  return (
    <MakePaymentLayout
      form={form}
      onSubmit={values => {
        createDocumentRequest.mutate(values);
      }}
      title={<Trans message="Make Payment" />}
      isLoading={createDocumentRequest.isPending}
      agree={agree}
    >
      <MakePaymentForm changeAgree={setAgree}/>
    </MakePaymentLayout>
  );
}
