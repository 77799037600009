import { DashboardNavbar } from '@app/dashboard/dashboard-navbar';
import { useProjects } from '@app/dashboard/use-projects';
import { useAccountUsage } from '@app/editor/use-account-usage';
import { AdHost } from '@common/admin/ads/ad-host';
import { useAuth } from '@common/auth/use-auth';
import {Trans} from '@common/i18n/trans';
import { useTrans } from '@common/i18n/use-trans';
import {StaticPageTitle} from '@common/seo/static-page-title';
import { Footer } from '@common/ui/footer/footer';
import { AnimatePresence } from 'framer-motion';
import React, {Fragment, ReactNode, useContext, useState} from 'react';
import { useForm } from 'react-hook-form';

import {
  CreatePaynowPayload,
  useCreatePaynow,
} from '../requests/create-paynow';

import { CreateMakePaymentPage } from './create-make-payment-page';

export function MakePaymentPage() {
  const {data: usage} = useAccountUsage();
  const {trans} = useTrans();
  const {user} = useAuth();
  const query = useProjects({userId: user!.id, paginate: 'lengthAware'});
  const {sortDescriptor, setSortDescriptor, searchQuery, setSearchQuery} =
    query;
  const sortValue = `${sortDescriptor.orderBy}|${sortDescriptor.orderDir}`;

  const form = useForm<CreatePaynowPayload>();
  const cratePaynow = useCreatePaynow(form);

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Make Payment" />
      </StaticPageTitle>
      <div className="flex h-screen flex-col overflow-y-scroll">
        <DashboardNavbar hideMakePayment={true}/>
        <div className="relative">
          <div className="mx-auto flex-auto px-12">
            <AdHost slot="dashboard_top" className="mb-36" />
            <AnimatePresence initial={false} mode="wait">
              <div className="min-h-full gap-12 overflow-x-hidden p-12 md:gap-24 md:p-24">
                <div className="mb-24 items-center justify-between gap-24 md:flex">
                  <StaticPageTitle><Trans message="Billing" /></StaticPageTitle>
                  <h1 className="mb-24 text-3xl font-light md:mb-0"><Trans message="Billing" /></h1>
                </div>
                <div className="flex flex-shrink-0 items-center overflow-x-auto gap-12 md:gap-24 mb-12 md:mb-24">
                  <div className="rounded-panel flex h-full flex-auto items-center gap-18 whitespace-nowrap p-20">
                    <div className="flex-auto">
                      <CreateMakePaymentPage/>
                    </div>
                  </div>

                  
                </div>
              </div>
            </AnimatePresence>
            <AdHost slot="dashboard_bottom" className="mt-36" />
          </div>
        </div>
        <Footer className="mt-40 px-40" />
      </div>
    </Fragment>
  );
}


