import {useTrans} from '@common/i18n/use-trans';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {useMutation} from '@tanstack/react-query';
import {toast} from '@common/ui/toast/toast';
import {message} from '@common/i18n/message';
import {apiClient, queryClient} from '@common/http/query-client';
import {DatatableDataQueryKey} from '@common/datatable/requests/paginated-resources';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {UseFormReturn} from 'react-hook-form';
import { MakePayment } from '@app_modules/billings/make-payment';

const endpoint = 'billing/stripe/make-payment';

export interface CreateMakePaymentPayload {
  amount: number;
}


export function useCreateMakePayment(form: UseFormReturn<MakePayment>) {
  const {trans} = useTrans();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (payload: CreateMakePaymentPayload) => createMakePayment(payload),
    onSuccess: () => {
      // toast(trans(message('MakePayment created')));
      // queryClient.invalidateQueries({queryKey: [endpoint]});
      // queryClient.invalidateQueries({
      //   queryKey: DatatableDataQueryKey('document-requests'),
      // });
      // navigate(callback_url ? callback_url : '/admin/document-requests');
      queryClient.invalidateQueries({
        queryKey: ['billing-usage'],
      });
    },
    onError: err => onFormQueryError(err, form),
  });
}

function createMakePayment(payload: CreateMakePaymentPayload) {
  
  return apiClient.post<Response>(endpoint, payload).then(r => r.data);
}
