import { Trans } from "@common/i18n/trans";
import { ConfirmationDialog } from "@common/ui/overlays/dialog/confirmation-dialog";

interface Props {
  onConfirm?: () => void;
  isLoading?: boolean;
}
export function LeaveTeamConfirmation({onConfirm, isLoading}: Props) {
  return (
    <ConfirmationDialog
      isDanger
      title={<Trans message="Leave team" />}
      isLoading={isLoading}
      onConfirm={onConfirm}
      body={
        <div>
          <Trans message="Are you sure you want to leave this team?" />
          <div className="mt-8 font-semibold">
            <Trans message="All resources you've created in the team will be transferred to team owner." />
          </div>
        </div>
      }
      confirm={<Trans message="Leave" />}
    />
  );
}
