import {useMutation} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient, queryClient} from '@common/http/query-client';
import {TeamQueryKeys} from './team-query-keys';
import {useAuth} from '@common/auth/use-auth';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';
import { useActiveTeamId } from '@app_modules/teams/active-team-id-context';
import { PersonalTeam } from '@app_modules/teams/user-teams';

interface Response extends BackendResponse {}

interface Props {
  teamId: number;
  memberId: number | string;
  memberType: 'invite' | 'member';
}

function removeMember({
  teamId,
  memberId,
  memberType,
}: Props): Promise<Response> {
  const endpoint =
    memberType === 'invite'
      ? `team/invite/${memberId}`
      : `team/${teamId}/member/${memberId}`;
  return apiClient.delete(endpoint).then(r => r.data);
}

export function useRemoveMember() {
  const {teamId, setTeamId} = useActiveTeamId();
  const {user} = useAuth();
  return useMutation({
    mutationFn: (props: Props) => removeMember(props),
    onSuccess: (response, props) => {
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.fetchUserTeams,
      });
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.teamWithMembers(props.teamId),
      });

      // if user left team that is currently active, switch to personal team
      if (props.memberId === user?.id && teamId === props.teamId) {
        setTeamId(PersonalTeam.id);
      }
    },
    onError: err => showHttpErrorToast(err),
  });
}
