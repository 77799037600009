import {useForm} from 'react-hook-form';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Form} from '@common/ui/forms/form';
import {Button} from '@common/ui/buttons/button';
import {DialogFooter} from '@common/ui/overlays/dialog/dialog-footer';
import {useDialogContext} from '@common/ui/overlays/dialog/dialog-context';
import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import { Trans } from '@common/i18n/trans';
import { Team } from './team';
import { UpdateTeamPayload } from '@app_modules/requests/update-team';
import { useUpdateTeam } from '@app_modules/requests/use-update-team';

interface Props {
  team: Team;
}
export function RenameTeamDialog({team}: Props) {
  const form = useForm<UpdateTeamPayload>({
    defaultValues: {id: team.id, name: team.name},
  });
  const {formId, close} = useDialogContext();
  const updateTeam = useUpdateTeam(form);

  return (
    <Dialog>
      <DialogHeader>
        <Trans message="Rename team" />
      </DialogHeader>
      <DialogBody>
        <Form
          form={form}
          id={formId}
          onSubmit={() => {
            updateTeam.mutate(form.getValues());
          }}
        >
          <FormTextField
            name="name"
            autoFocus
            label={<Trans message="Name" />}
            minLength={3}
            required
          />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" onClick={close}>
          <Trans message="Cancel" />
        </Button>
        <Button
          variant="flat"
          color="primary"
          type="submit"
          form={formId}
          disabled={updateTeam.isPending}
        >
          <Trans message="Rename" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
