import React, {Fragment} from 'react';
import {DataTablePage} from '@common/datatable/page/data-table-page';
import {IconButton} from '@common/ui/buttons/icon-button';
import {EditIcon} from '@common/icons/material/Edit';
import {FormattedDate} from '@common/i18n/formatted-date';
import {ColumnConfig} from '@common/datatable/column-config';
import {Trans} from '@common/i18n/trans';
import {DataTableEmptyStateMessage} from '@common/datatable/page/data-table-emty-state-message';
import softwareEngineerSvg from '@common/admin/tags/software-engineer.svg';
import {DataTableAddItemButton} from '@common/datatable/data-table-add-item-button';
import {BillingTransaction} from './billing-transaction';
import {Link} from 'react-router-dom';
import {Tooltip} from '@common/ui/tooltip/tooltip';
import {useDeleteBillingTransaction} from '@app_modules/admin/billing-transactions/requests/use-delete-billing-transaction';
import {DeleteIcon} from '@common/icons/material/Delete';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {BillingTransactionsIndexPageFilters} from './billing-transactions-index-page-filters';
import { VisibilityIcon } from '@common/icons/material/Visibility';
import moment from 'moment-timezone';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { CreateBillingTransactionDialog } from './create-billing-transaction-dialog';
import { UpdateBillingTransactionDialog } from './update-billing-transaction-dialog';
import { currencyFormat } from '@common/utils/string/money-format';

export function BillingTransactionsIndexPage() {
  const navigate = useNavigate();
  const timezone = useUserTimezone();
  
  const columnConfig: ColumnConfig<BillingTransaction>[] = [
    {
      key: 'id',
      allowsSorting: false,
      maxWidth: 'max-w-80',
      header: () => <Trans message="Transaction #" />,
      body:  billingTransaction => billingTransaction.id,
    },
    {
      key: 'created_at',
      allowsSorting: false,
      maxWidth: 'max-w-120',
      header: () => <Trans message="Created Date" />,
      body: billingTransaction => billingTransaction.created_at ? moment.utc(billingTransaction.created_at).tz(timezone).format('MM/DD/YYYY HH:mm') :'',
    },
    {
      key: 'order_by',
      allowsSorting: false,
      maxWidth: 'max-w-140',
      header: () => <Trans message="Billing By" />,
      body: billingTransaction => billingTransaction.order_by,
    },
    {
      key: 'payment_method',
      allowsSorting: false,
      visibleInMode: 'all',
      maxWidth: 'max-w-120',
      header: () => <Trans message="Payment Method" />,
      body:  billingTransaction => billingTransaction.payment_method,
    },
    {
      key: 'begin_balance',
      allowsSorting: false,
      visibleInMode: 'all',
      maxWidth: 'max-w-120',
      header: () => <Trans message="Begin Balance" />,
      body:  billingTransaction => currencyFormat(billingTransaction.begin_balance),
    },
    {
      key: 'amount',
      allowsSorting: false,
      visibleInMode: 'all',
      maxWidth: 'max-w-120',
      header: () => <Trans message="Amount" />,
      body:  billingTransaction => billingTransaction.type == 'debit' ? currencyFormat(billingTransaction.amount) : '$0.00',
    },
    {
      key: 'deposits',
      allowsSorting: false,
      visibleInMode: 'all',
      maxWidth: 'max-w-120',
      header: () => <Trans message="Deposits" />,
      body:  billingTransaction => billingTransaction.type == 'credit' ? currencyFormat(billingTransaction.amount) : '$0.00',
    },
    {
      key: 'ending_balance',
      allowsSorting: false,
      visibleInMode: 'all',
      maxWidth: 'max-w-120',
      header: () => <Trans message="Ending Balance" />,
      body:  billingTransaction => currencyFormat(billingTransaction.ending_balance),
    },
    {
      key: 'status',
      allowsSorting: false,
      visibleInMode: 'all',
      maxWidth: 'max-w-120',
      header: () => <Trans message="Status" />,
      body:  billingTransaction => billingTransaction.type == 'credit' ? 'Credit' : 'Debit',
    },
    {
      key: 'note',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Description" />,
      body:  billingTransaction => billingTransaction.note,
    },
    {
      key: 'actions',
      header: () => <Trans message="Actions" />,
      visibleInMode: 'all',
      hideHeader: true,
      align: 'end',
      maxWidth: 'max-w-50',
      body:  billingTransaction => {
        return (
          <Fragment>
            <IconButton
              size="md"
              className="text-muted"
              elementType={Link}
              to={`/billing-transactions/${ billingTransaction.id}/view`}
            >
              <VisibilityIcon />
            </IconButton>
          </Fragment>
        );
        
      },
    },
  ];

  return (
    <DataTablePage
      endpoint="billing-transactions"
      title={<Trans message="Billing Transactions" />}
      columns={columnConfig}
      actions={<Actions />}
      enableSelection={false}
      filters={BillingTransactionsIndexPageFilters}
      // onRowAction={item => {
      //   navigate(`/reports/${item.report_id}/view`);
      // }}
      emptyStateMessage={
        <DataTableEmptyStateMessage
          image={softwareEngineerSvg}
          title={<Trans message="No Billing Transactions have been created yet" />}
          filteringTitle={<Trans message="No matching Billing Transactions" />}
        />
      }
    />
  );
}

interface DeleteBillingTransactionButtonProps {
  billingTransaction: BillingTransaction;
}
function DeleteBillingTransactionButton({ billingTransaction}: DeleteBillingTransactionButtonProps) {
  const deleteBillingTransaction = useDeleteBillingTransaction();
  return (
    <DialogTrigger
      type="modal"
      onClose={confirmed => {
        if (confirmed) {
          deleteBillingTransaction.mutate({ id:  billingTransaction.id});
        }
      }}
    >
      <Tooltip label={<Trans message="Delete Documents Request Transaction" />}>
        <IconButton
          size="md"
          className="text-muted"
          disabled={deleteBillingTransaction.isPending}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title={<Trans message="Delete billingTransaction" />}
        body={<Trans message="Are you sure you want to delete this Documents Request Transaction?" />}
        confirm={<Trans message="Delete" />}
      />
    </DialogTrigger>
  );
}


function Actions() {
  return (
    <Fragment>
      <DataTableAddItemButton elementType={Link} to="/billings/make-payment">
        <Trans message="Make Payment" />
      </DataTableAddItemButton>
    </Fragment>
  );
}