// import {useMutation, useQueryClient} from '@tanstack/react-query';
// import {UseFormReturn} from 'react-hook-form';
// import {apiClient} from '@common/http/query-client';
// import {toast} from '@common/ui/toast/toast';
// import {useTrans} from '@common/i18n/use-trans';
// import {onFormQueryError} from '@common/errors/on-form-query-error';
// import {message} from '@common/i18n/message';
// import {BackendResponse} from '@common/http/backend-response/backend-response';
// import { Team } from '@app_modules/teams/team';

// interface Response extends BackendResponse {
//   team: Team;
// }

// export interface TeamPayload {
//   name: string;
// }

// export function useUpdateTeam(
//   id: string|number,
//   form: UseFormReturn<TeamPayload>,
// ) {
//   const {trans} = useTrans();
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: (payload: TeamPayload) => updateTeam(id, payload),
//     onSuccess: async () => {
//       debugger;
//       await queryClient.invalidateQueries({queryKey: ['teams']});
//       toast(trans(message('Team updated')));
//     },
//     onError: err => onFormQueryError(err, form),
//   });
// }

// async function updateTeam(
//   id: string|number,
//   payload: TeamPayload,
// ) {
//   return apiClient
//     .put<Response>(`team/${id}`, payload)
//     .then(r => r.data);
// }

import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {TeamQueryKeys} from './team-query-keys';
import { BackendResponse } from '@common/http/backend-response/backend-response';
import { Team } from '@app_modules/teams/team';
import { apiClient, queryClient } from '@common/http/query-client';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { toast } from '@common/ui/toast/toast';
import { message } from '@common/i18n/message';
import { onFormQueryError } from '@common/errors/on-form-query-error';

interface Response extends BackendResponse {
  team: Team;
}

export interface UpdateTeamPayload {
  id: number;
  name: string;
}

function updateTeam({
  id,
  ...props
}: UpdateTeamPayload): Promise<Response> {
  return apiClient.put(`team/${id}`, props).then(r => r.data);
}

export function useUpdateTeam(
  form: UseFormReturn<UpdateTeamPayload>,
) {
  const {close} = useDialogContext();
  return useMutation({
    mutationFn: (props: UpdateTeamPayload) => updateTeam(props),
    onSuccess: response => {
      close();
      toast(message('Updated team'));
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.fetchTeams,
      });
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.fetchUserTeams,
      });
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.teamWithMembers(
          response.team.id,
        ),
      });
    },
    onError: r => onFormQueryError(r, form),
  });
}
