import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode} from 'react';
import {FormSelect} from '@common/ui/forms/select/select';
import {Item} from '@common/ui/forms/listbox/item';
import { BillingTransaction } from './billing-transaction';
import { FormDatePicker } from '@common/ui/forms/input-field/date/date-picker/date-picker';
import { Button } from '@common/ui/buttons/button';
import { useSettings } from '@common/core/settings/use-settings';
import moment from 'moment-timezone';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { currencyFormat } from '@common/utils/string/money-format';

interface DetailPanelProps {
  billingTransaction: BillingTransaction;
}
export function BillingTransactionDetail({billingTransaction}: DetailPanelProps) {
  const timezone = useUserTimezone();

  return (
    <Fragment>

      <div className="flex flex-wrap border-b-1 pb-20">
        <div className='w-full'>
          <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
              <div className="flex items-center col-span-1 text-xs">
                <Trans message="Transaction #"/>:
              </div>
              <div className="flex items-center font-bold text-sm col-span-3">
                {billingTransaction.id}
              </div>
              <div className="flex items-center col-span-1 text-xs">
                <Trans message="Amount"/>:
              </div>
              <div className="flex items-center font-bold text-sm col-span-2">
                {currencyFormat(billingTransaction.amount)}
              </div>
              <div className="flex items-center col-span-1 text-xs">
                <Trans message="Status"/>:
              </div>
              <div className="flex items-center font-bold text-sm col-span-2">
                {billingTransaction.status}
              </div>
          </div>
          <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
            <div className="flex items-center col-span-1 text-xs">
              <Trans message="Create Date"/>:
            </div>
            <div className="flex items-center font-bold text-sm col-span-3">
              {moment.utc(billingTransaction.created_at).tz(timezone).format('MM/DD/YYYY')}
            </div>
            <div className="flex items-center col-span-1 text-xs">
              <Trans message="Completed Date"/>:
            </div>
            <div className="flex items-center font-bold text-sm col-span-2">
            {billingTransaction.completed_date ? moment.utc(billingTransaction.completed_date).tz(timezone).format('MM/DD/YYYY') : 'N/A'}
            </div>
            <div className="flex items-center col-span-1 text-xs">
              <Trans message="Order By"/>:
            </div>
            <div className="flex items-center font-bold text-sm col-span-2">
              {billingTransaction.order_by}
            </div>
          </div>
          <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
            <div className="flex items-center col-span-1 text-xs">
              <Trans message="Note"/>:
            </div>
            <div className="flex items-center font-bold text-sm col-span-11">
              {billingTransaction.note}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

interface HeaderProps {
  children: ReactNode;
}
function Header({children}: HeaderProps) {
  return <h2 className="mt-40 mb-20 text-base font-semibold">{children}</h2>;
}


