import {useForm} from 'react-hook-form';
import {useId} from 'react';
import {User} from '../../../user';
import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {Form} from '@common/ui/forms/form';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {useUpdateAccountDetails} from './update-account-details';
import {Trans} from '@common/i18n/trans';
import {FormImageSelector} from '@common/ui/images/image-selector';
import {FileUploadProvider} from '@common/uploads/uploader/file-upload-provider';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import { useUploadHeaderLogo } from '../avatar/upload-header-logo';
import { useRemoveHeaderLogo } from '../avatar/remove-header-logo';
import { useUpdateReportDetails } from './update-report-details';
import { ReportSettingsPanel } from '../report-settings-panel';

interface Props {
  user: User;
}
export function ReportInfoPanel({user}: Props) {
  const uploadHeaderLogo = useUploadHeaderLogo({user});
  const removeHeaderLogo = useRemoveHeaderLogo({user});
  const formId = useId();
  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      report_name: user.report_name || '',
      report_description: user.report_description || '',
      document_report_name: user.document_report_name || '',
      document_report_description: user.document_report_description || '',
      report_footer: user.report_footer || '',
      header_logo: user.header_logo,
    },
  });
  const updateDetails = useUpdateReportDetails(form);

  return (
    <ReportSettingsPanel
      id={AccountSettingsId.ReportDetails}
      title={<Trans message="Update pdf report info" />}
      actions={
        <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateDetails.isPending || !form.formState.isValid}
        >
          <Trans message="Save" />
        </Button>
      }
    >
      <Form
        form={form}
        className="flex flex-col flex-col-reverse md:flex-row items-center gap-40 md:gap-80"
        onSubmit={newDetails => {
          updateDetails.mutate(newDetails);
        }}
        id={formId}
      >
        <div className="flex-auto w-full">
          <FormTextField
            className="mb-24"
            name="report_name"
            label={<Trans message="BSA Report name" />}
          />
          <FormTextField
            name="report_description"
            inputElementType="textarea"
            label={<Trans message="BSA Report Description" />}
          />
          <FormTextField
            className="mb-24"
            name="document_report_name"
            label={<Trans message="Document Report name" />}
          />
          <FormTextField
            name="document_report_description"
            inputElementType="textarea"
            label={<Trans message="Document Report Description" />}
          />
          <FormTextField
            name="report_footer"
            label={<Trans message="Report Footer" />}
          />

        <FileUploadProvider>
          <FormImageSelector
            className="md:mr-80"
            variant="square"
            previewSize="w-900 h-200"
            showRemoveButton
            name="header_logo"
            diskPrefix="logos"
            label={<Trans message="Report Header Logo" />}
            onChange={url => {
              if (url) {
                uploadHeaderLogo.mutate({url});
              } else {
                removeHeaderLogo.mutate();
              }
            }}
          />
        </FileUploadProvider>
        </div>

      </Form>
    </ReportSettingsPanel>
  );
}
