import {Outlet, RouteObject, useRoutes} from 'react-router-dom';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import { AuthRoute } from '@common/auth/guards/auth-route';
import { MakePaymentPage } from './make-payment-page';
import { AccountBillingPage } from '@app_modules/account/account-billing-page';

export default function BillingsRoutes() {
  const DashboardRouteConfig: RouteObject[] = [
    {
      path: '',
      element: (
        <ActiveWorkspaceProvider>
          <Outlet />
        </ActiveWorkspaceProvider>
      ),
      children: [
        {
          path: '/make-payment',
          element: (
            <AuthRoute permission="billings.view">
              <MakePaymentPage />
            </AuthRoute>
          ),
        },

        {
          path: '/account-billing',
          element: (
            <AuthRoute permission="billings.view">
              <AccountBillingPage />
            </AuthRoute>
          ),
        },
        

        {path: '*', element: <NotFoundPage />},
      ],
    },
  ];

  return useRoutes(DashboardRouteConfig);
}
