import {Outlet, RouteObject, useRoutes} from 'react-router-dom';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import { BillingTransactionPage, ViewBillingTransactionPage } from './billing-transactions-page';
import { AuthRoute } from '@common/auth/guards/auth-route';

export default function BillingTransactionRoutes() {
  const DashboardRouteConfig: RouteObject[] = [
    {
      path: '',
      element: (
        <ActiveWorkspaceProvider>
          <Outlet />
        </ActiveWorkspaceProvider>
      ),
      children: [
        {
          path: '/',
          element: (
            <AuthRoute permission="billing_transactions.view">
              <BillingTransactionPage />
            </AuthRoute>
          ),
        },
        
        // {
        //   path: 'new',
        //   element: (
        //     <AuthRoute permission="billing_transactions.create">
        //       <NewBillingTransactionPage />
        //     </AuthRoute>
        //   ),
        // },
        // {
        //   path: ':billingTransactionId/edit',
        //   element: (
        //     <AuthRoute permission="billing_transactions.update">
        //       <UpdateBillingTransactionPage />
        //     </AuthRoute>
        //   ),
        // },
        {
          path: ':billingTransactionId/view',
          element: (
            <AuthRoute permission="billing_transactions.view">
              <ViewBillingTransactionPage />
            </AuthRoute>
          ),
        },

        {path: '*', element: <NotFoundPage />},
      ],
    },
  ];

  return useRoutes(DashboardRouteConfig);
}
