import {useForm} from 'react-hook-form';
import {useId} from 'react';
import {User} from '../../../user';
import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {Form} from '@common/ui/forms/form';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import { useUpdateWebhook } from './update-webhook';

interface Props {
  user: User;
}
export function WebhookPanel({user}: Props) {
  const formId = useId();
  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      webhook_url: user.webhook_url || ''
    },
  });
  const updateDetails = useUpdateWebhook(form);

  return (
    <AccountSettingsPanel
      id={AccountSettingsId.Webhook}
      title={<Trans message="Webhook" />}
      actions={
        <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateDetails.isPending || !form.formState.isValid}
        >
          <Trans message="Save" />
        </Button>
      }
    >
      <p className="text-sm">
        <Trans message="A webhook must be set to the POST method." />
      </p>
      <Form
        form={form}
        className="flex flex-col flex-col-reverse md:flex-row items-center gap-40 md:gap-80"
        onSubmit={newDetails => {
          updateDetails.mutate(newDetails);
        }}
        id={formId}
      >
        
        <div className="flex-auto w-full">
          <FormTextField
            name="webhook_url"
            label={<Trans message="Webhook" />}
          />
        </div>
      </Form>
    </AccountSettingsPanel>
  );
}
