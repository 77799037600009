import React, {useContext, useEffect, useMemo} from 'react';
import {useCookie} from '@common/utils/hooks/use-cookie';
import { Team } from './team';
import { PersonalTeam, useUserTeams } from './user-teams';
import { setActiveTeamId } from './active-team-id';
// import { useUserTeams } from '@common/team/user-teams';

export interface ActiveTeamIdContextValue {
  teamId: number | null;
  setTeamId: (id: number) => void;
}

// add default context value so it does not error out, if there's no context provider
export const ActiveTeamIdContext =
  React.createContext<ActiveTeamIdContextValue>({
    // set default as null, so it's not sent via query params in admin and
    // other places if component is not wrapped in team context explicitly
    teamId: null,
    setTeamId: () => {},
  });

export function useActiveTeamId(): ActiveTeamIdContextValue {
  return useContext(ActiveTeamIdContext);
}

export function useActiveTeam(): Team | null | undefined {
  const {teamId} = useActiveTeamId();
  const query = useUserTeams();
  if (query.data) {
    return query.data.find(team => team.id === teamId);
  }
  return null;
}

interface ActiveTeamProviderProps {
  children: any;
}
export function ActiveTeamProvider({
  children,
}: ActiveTeamProviderProps) {
  const [teamId, setCookieId] = useCookie(
    'activeTeamId',
    `${PersonalTeam.id}`
  );

  useEffect(() => {
    setActiveTeamId(parseInt(teamId));
    // clear team id when unmounting team provider
    return () => {
      setActiveTeamId(0);
    };
  }, [teamId]);

  const contextValue: ActiveTeamIdContextValue = useMemo(() => {
    return {
      teamId: parseInt(teamId),
      setTeamId: (id: number) => {
        setCookieId(`${id}`);
      },
    };
  }, [teamId, setCookieId]);

  return (
    <ActiveTeamIdContext.Provider value={contextValue}>
      {children}
    </ActiveTeamIdContext.Provider>
  );
}
