import {useMutation, useQueryClient} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {message} from '@common/i18n/message';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { Team } from '@app_modules/teams/team';

interface Response extends BackendResponse {
  team: Team;
}

export interface TeamPayload {
  request_description: string;
}

export function useCreateTeam(form: UseFormReturn<TeamPayload>) {
  const {trans} = useTrans();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: TeamPayload) => createTeam(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['teams']});
      queryClient.invalidateQueries({
        queryKey: ['billing-usage'],
      });
      toast(trans(message('Team created')));
    },
    onError: err => onFormQueryError(err, form),
  });
}

async function createTeam(payload: TeamPayload) {
  return apiClient
    .post<Response>(`team`, payload)
    .then(r => r.data);
}

