import {useQuery} from '@tanstack/react-query';
import {TeamQueryKeys} from './team-query-keys';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import { Team } from '@app_modules/teams/team';

export interface FetchTeamWithMembersResponse extends BackendResponse {
  team: Team;
}

function fetchTeamWithMembers(
  teamId: number,
): Promise<FetchTeamWithMembersResponse> {
  return apiClient
    .get(`team/${teamId}`)
    .then(response => response.data);
}

export function useTeamWithMembers(teamId: number) {
  return useQuery({
    queryKey: TeamQueryKeys.teamWithMembers(teamId),
    queryFn: () => fetchTeamWithMembers(teamId),
  });
}
