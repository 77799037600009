import {useMutation} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {apiClient, queryClient} from '@common/http/query-client';
import {TeamQueryKeys} from './team-query-keys';
import {message} from '@common/i18n/message';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';
import { TeamInvite } from '@app_modules/teams/team-invite';
import { TeamMember } from '@app_modules/teams/team-member';

interface Response extends BackendResponse {}

interface Props {
  teamId: number;
  member: TeamMember | TeamInvite;
  roleId: number;
}

function ChangeRole({teamId, member, ...other}: Props): Promise<Response> {
  const modelType = member.model_type;
  const memberId =
    member.model_type === 'invite' ? member.id : member.member_id;
  return apiClient
    .post(
      `team/${teamId}/${modelType}/${memberId}/change-role`,
      other,
    )
    .then(r => r.data);
}

export function useChangeRole() {
  return useMutation({
    mutationFn: (props: Props) => ChangeRole(props),
    onSuccess: (response, props) => {
      toast(message('Role changed'));
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.teamWithMembers(props.teamId),
      });
    },
    onError: err => showHttpErrorToast(err),
  });
}
