import { TeamInvite } from '@app_modules/teams/team-invite';
import { BackendResponse } from '@common/http/backend-response/backend-response';
import { apiClient, queryClient } from '@common/http/query-client';
import { showHttpErrorToast } from '@common/utils/http/show-http-error-toast';
import {useMutation} from '@tanstack/react-query';
import { TeamQueryKeys } from './team-query-keys';

interface Response extends BackendResponse {
  invites: TeamInvite[];
}

interface Props {
  teamId: number;
  emails: string[];
  roleId: number;
}

function InviteMembers({teamId, ...other}: Props): Promise<Response> {
  return apiClient
    .post(`team/${teamId}/invite`, other)
    .then(r => r.data);
}

export function useInviteMembers() {
  return useMutation({
    mutationFn: (props: Props) => InviteMembers(props),
    onSuccess: (response, props) => {
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.teamWithMembers(props.teamId),
      });
    },
    onError: err => showHttpErrorToast(err),
  });
}
