import {useQuery} from '@tanstack/react-query';
import { Team } from './team';
import { BackendResponse } from '@common/http/backend-response/backend-response';
import { apiClient } from '@common/http/query-client';
import { TeamQueryKeys } from '@app_modules/requests/team-query-keys';

export interface FetchUserTeamsResponse extends BackendResponse {
  teams: Team[];
}

export const PersonalTeam: Team = {
  name: 'Default',
  default: true,
  id: 0,
  members_count: 1,
};

function fetchUserTeams(): Promise<FetchUserTeamsResponse> {
  return apiClient.get(`me/teams`).then(response => response.data);
}

function addPersonalTeamToResponse(response: FetchUserTeamsResponse) {
  return [PersonalTeam, ...response.teams];
}

export function useUserTeams() {
  return useQuery({
    
    queryKey: TeamQueryKeys.fetchUserTeams,
    queryFn: fetchUserTeams,
    placeholderData: {teams: []},
    select: addPersonalTeamToResponse,
  });
}
