import {useTeams} from '../use-teams';
import {useAuth} from '@common/auth/use-auth';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import {useTrans} from '@common/i18n/use-trans';
import {message} from '@common/i18n/message';
import {Footer} from '@common/ui/footer/footer';
import {AnimatePresence, m} from 'framer-motion';
import {DashboardNavbar} from '@app/dashboard/dashboard-navbar';
import {useAccountUsage} from '@app/editor/use-account-usage';
import {AdHost} from '@common/admin/ads/ad-host';
import {StaticPageTitle} from '@common/seo/static-page-title';
import { TeamsIndexPage } from './teams-index-page';
import { CreateTeamPage } from '@app_modules/admin/teams/crupdate-team-page/create-team-page';
import { EditTeamPage } from '@app_modules/admin/teams/crupdate-team-page/edit-team-page';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import { DashboardWorkspaceSelector } from '@app/dashboard/dashboard-workspace-selector';
// import { TeamViewPage } from './team-view-page';

const SortOptions = [
  {value: 'created_at|desc', label: message('Request Date')},
  {value: 'first_name|asc', label: message('Alphabetical')},
  {value: 'updated_at|desc', label: message('Last updated')},
];

export function TeamPage() {
  const {data: usage} = useAccountUsage();
  const navigate = useNavigate();
  const {trans} = useTrans();
  const {user} = useAuth();
  const userId = user!.id;
  const query = useTeams({userId: user!.id, paginate: 'lengthAware'});
  const {sortDescriptor, setSortDescriptor, searchQuery, setSearchQuery} =
    query;
  const sortValue = `${sortDescriptor.orderBy}|${sortDescriptor.orderDir}`;

  // useEffect(() => {
  //   window.Echo.channel('report').listen('.new', (e: any) => {
  //     if (e.data[userId] != undefined) {
  //       const item = e.data[userId];
  //       if (item.report_id) {
  //         navigate(`/reports/${item.report_id}/view`);
  //       }
  //     }
  //   });
  // }, [])

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Teams
" />
      </StaticPageTitle>
      <div className="flex h-screen flex-col overflow-y-scroll">
        <DashboardNavbar />
        <div className="relative">
          <div className=" mx-auto flex-auto px-12">
            <div className="items-center gap-12 py-361">
              <div className='mr-auto'>
              <AdHost slot="dashboard_top" className="mb-36" />
              <AnimatePresence initial={false} mode="wait">

                <TeamsIndexPage/>
              
              </AnimatePresence>
              <AdHost slot="dashboard_bottom" className="mt-36" />
              </div>
            </div>
          </div>
        </div>
        
        <Footer className="mt-40 px-40" />
      </div>
    </Fragment>
  );
}

export function NewTeamPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="New Team" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="relative">
        <div className="container mx-auto mt-40 px-20">
          <CreateTeamPage callback_url="/teams"/>
        </div>
      </div>
      <Footer className="mt-40 px-40" />

    </div>
  );
}


export function UpdateTeamPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="Edit Team" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="relative">
        <div className="container mx-auto mt-40 px-20">
          <EditTeamPage callback_url="/teams"/>
        </div>
      </div>
      <Footer className="mt-40 px-40" />
    </div>
  );
}

export function ViewTeamPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="Team Detail" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="relative">
        <div className=" mx-auto mt-40 px-20">
          {/* <TeamViewPage callback_url="/teams"/> */}
        </div>
      </div>
      <Footer className="mt-40 px-40" />
    </div>
  );
}






