import {
  BackendFilter,
  FilterControlType,
  FilterOperator,
} from '@common/datatable/filters/backend-filter';
import {message} from '@common/i18n/message';
import {
  createdAtFilter,
  updatedAtFilter,
} from '@common/datatable/filters/timestamp-filters';

export const BillingTransactionsIndexPageFilters: BackendFilter[] = [
  {
    key: 'status',
    label: message('Status'),
    description: message('Status'),
    defaultOperator: FilterOperator.eq,
    control: {
      type: FilterControlType.Select,
      defaultValue: '',
      options: [
        {
          key: 'Pending',
          label: message('Pending'),
          value: {value: '*', operator: FilterOperator.eq},
        },
        {
          key: 'Completed',
          label: message('Completed'),
          value: {value: '*', operator: FilterOperator.eq},
        },
        {
          key: 'Cancelled',
          label: message('Cancelled'),
          value: {value: '*', operator: FilterOperator.eq},
        },
      ],
    },
  },
  {
    key: 'type',
    label: message('Payment Type'),
    description: message('Payment Type'),
    defaultOperator: FilterOperator.eq,
    control: {
      type: FilterControlType.Select,
      defaultValue: '',
      options: [
        {
          key: 'credit',
          label: message('Make Payment'),
          value: {value: '*', operator: FilterOperator.eq},
        },
        {
          key: 'debit',
          label: message('Charged'),
          value: {value: '*', operator: FilterOperator.eq},
        },
      ],
    },
  },
  createdAtFilter({
    label: message('Payment Date'),
    description: message('Payment Date'),
  })
];
