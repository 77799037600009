import {useMutation} from '@tanstack/react-query';
import {toast} from '../../../../ui/toast/toast';
import {BackendResponse} from '../../../../http/backend-response/backend-response';
import {User} from '../../../user';
import {message} from '../../../../i18n/message';
import {apiClient} from '../../../../http/query-client';
import {showHttpErrorToast} from '../../../../utils/http/show-http-error-toast';

interface Response extends BackendResponse {}

interface UserProps {
  user: User;
}

function removeHeaderLogo(user: User): Promise<Response> {
  return apiClient.delete(`users/${user.id}/header-logo`).then(r => r.data);
}

export function useRemoveHeaderLogo({user}: UserProps) {
  return useMutation({
    mutationFn: () => removeHeaderLogo(user),
    onSuccess: () => {
      toast(message('Removed header logo report'));
    },
    onError: err => showHttpErrorToast(err),
  });
}
