import {Outlet, RouteObject, useRoutes} from 'react-router-dom';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import { AuthRoute } from '@common/auth/guards/auth-route';
// import { TeamMemberPage } from '@app_modules/member/team-member-page';
import { TeamPage } from './teams-page';
import { ActiveTeamProvider } from './active-team-id-context';

export default function TeamsRoutes() {
  const DashboardRouteConfig: RouteObject[] = [
    {
      path: '',
      element: (
        <ActiveTeamProvider>
          <Outlet />
        </ActiveTeamProvider>
      ),
      children: [
        {
          path: '/',
          element: (
            <AuthRoute permission="teams.view">
              <TeamPage />
            </AuthRoute>
          ),
        },

        // {
        //   path: '/make-payment',
        //   element: (
        //     <AuthRoute permission="teams.view">
        //       <MakePaymentPage />
        //     </AuthRoute>
        //   ),
        // },

        // {
        //   path: '/members',
        //   element: (
        //     <AuthRoute permission="teams.view">
        //       <TeamMemberPage />
        //     </AuthRoute>
        //   ),
        // },

        // {
        //   path: 'new',
        //   element: (
        //     <AuthRoute permission="document_requests.create">
        //       <NewTeamPage />
        //     </AuthRoute>
        //   ),
        // },
        // {
        //   path: ':teamId/edit',
        //   element: (
        //     <AuthRoute permission="document_requests.update">
        //       <UpdateTeamPage />
        //     </AuthRoute>
        //   ),
        // },
        

        {path: '*', element: <NotFoundPage />},
      ],
    },
  ];

  return useRoutes(DashboardRouteConfig);
}
