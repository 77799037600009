import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import {useParams} from 'react-router-dom';
import { BillingTransaction } from '@app_modules/billing-transactions/billing-transaction';
//import {BillingTransaction} from '../../billing-transactions/billing-transaction';

const Endpoint = (id: number | string) => `billing-transactions/${id}`;

export interface FetchRoleResponse extends BackendResponse {
   billingTransaction: BillingTransaction;
}

export function useBillingTransaction() {
  const { billingTransactionId} = useParams();
  return useQuery({
    queryKey: [Endpoint( billingTransactionId!)],
    queryFn: () => fetchBillingTransaction( billingTransactionId!),
  });
}

function fetchBillingTransaction( billingTransactionId: number | string): Promise<FetchRoleResponse> {
  return apiClient.get(Endpoint( billingTransactionId)).then(response => response.data);
}
