import {useMutation} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {apiClient} from '@common/http/query-client';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';
import { TeamInvite } from '@app_modules/teams/team-invite';

interface Response extends BackendResponse {
  invite: TeamInvite;
}

interface Props {
  teamId: number;
  inviteId: string;
}

function ResendInvite({
  teamId,
  inviteId,
  ...other
}: Props): Promise<Response> {
  return apiClient
    .post(`team/${teamId}/${inviteId}/resend`, other)
    .then(r => r.data);
}

export function useResendInvite() {
  return useMutation({
    mutationFn: (props: Props) => ResendInvite(props),
    onSuccess: () => {
      toast('Invite sent');
    },
    onError: err => showHttpErrorToast(err),
  });
}
