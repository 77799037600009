import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode} from 'react';
import { useSettings } from '@common/core/settings/use-settings';
import { FormCheckbox } from '@common/ui/forms/toggle/checkbox';
import { useFormContext } from 'react-hook-form';
import { MakePayment } from './make-payment';

export function MakePaymentForm({changeAgree}: {changeAgree: any}) {

  const {billing} = useSettings();

  return (
    <Fragment>
      <FormCheckbox
        name="agree"
        className="mb-2 block"
         value={1}
        onChange={e => changeAgree(e.target.checked)}      
      ><b>
        <Trans message="Agree Terms & Conditions Payment" /></b>
      </FormCheckbox>
      <div className='max-full'>
        <div className='text-sm mb-24 text-wrap'>
          {billing?.terms_conditions}
        </div>
      </div>
    </Fragment>
  );
}

interface HeaderProps {
  children: ReactNode;
}
function Header({children}: HeaderProps) {
  return <h2 className="mt-40 mb-20 text-base font-semibold">{children}</h2>;
}


