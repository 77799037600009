import React, {Fragment} from 'react';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import {useSettings} from '@common/core/settings/use-settings';
import {useFormContext} from 'react-hook-form';

export function CrupdateTeamFields() {
  const {builder} = useSettings();
  const form = useFormContext();
  return (
    <Fragment>
      <FormTextField
        autoFocus
        name="name"
        label={<Trans message="Team Name" />}
        className="mb-20"
        required
      />
    </Fragment>
  );
}
