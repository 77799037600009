import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {Trans} from '@common/i18n/trans';
import { ViewResourceLayout } from './view-resource-layout';
import { useState } from 'react';
import { useBillingTransaction } from '@app_modules/requests/use-billing-transaction';
import { BillingTransaction } from './billing-transaction';
import { BillingTransactionDetail } from './billing-transaction-detail';
import { useSettings } from '@common/core/settings/use-settings';


interface ViewBillingTransactionProps {
  callback_url: string;
}

export function BillingTransactionViewPage({callback_url}: ViewBillingTransactionProps) {
  const query = useBillingTransaction();


  if (query.status !== 'success') {
    return <FullPageLoader />;
  }

  return <PageContent billingTransaction={query.data.billingTransaction}/>;
}

interface PageContentProps {
   billingTransaction: BillingTransaction;
}
function PageContent({ billingTransaction}: PageContentProps) {
    // const [newPhase, setNewPhase] = useState('');
    // const [editPhase, setEditPhase] = useState('');
    // const [deletePhase, setDeletePhase] = useState('');

    // const [newTask, setNewTask] = useState('');
    // const [deleteTask, setDeleteTask] = useState('');

    // const fetchPhases =  useCallback(async () => {
    //   return await fetch(`api/v1/projects/${project.id}/list-phases`);
    // }, [newPhase, editPhase, deletePhase, newTask, deleteTask]);

  const {document} = useSettings();
  const document_types = document ? JSON.parse(document.document_types):[];
  
  //const [reportId, setReportId] = useState();
  const [pageType, setPageType] = useState('index');
  const [documentType, setDocumentType] = useState<any>(null);
  const [newDocumentType, setNewDocumentType] = useState<any>(null);
  const changeDocumentType = (documentType: any) => {
    setDocumentType(documentType);
  }
  const changePageType = (pageType: any) => {
    setPageType(pageType);
  }

  return (
    <ViewResourceLayout
      title={
        <Trans message="Billing Transaction #:id" values={{id:  billingTransaction.id}} />
      }
      // isLoading={viewBillingTransaction.isPending}
    >
      <BillingTransactionDetail billingTransaction={billingTransaction}/>

    </ViewResourceLayout>
  );
  
}
