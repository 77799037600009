import { DashboardNavbar } from '@app/dashboard/dashboard-navbar';
import { useProjects } from '@app/dashboard/use-projects';
import { useAccountUsage } from '@app/editor/use-account-usage';
import { AdHost } from '@common/admin/ads/ad-host';
import { useUserReport } from '@common/admin/analytics/use-user-report';
import { UserHeaderReport } from '@common/admin/analytics/user-header-report';
import { useAuth } from '@common/auth/use-auth';
import { PolicyFailMessage } from '@common/billing/upgrade/policy-fail-message';
import {Trans} from '@common/i18n/trans';
import { useTrans } from '@common/i18n/use-trans';
import { MoneyIcon } from '@common/icons/material/Money';
import {StaticPageTitle} from '@common/seo/static-page-title';
import { Divider } from '@common/text-editor/menubar/divider';
import { Accordion, AccordionItem } from '@common/ui/accordion/accordion';
import { Button } from '@common/ui/buttons/button';
import { Footer } from '@common/ui/footer/footer';
import { DateRangeValue } from '@common/ui/forms/input-field/date/date-range-picker/date-range-value';
import { DateRangePresets } from '@common/ui/forms/input-field/date/date-range-picker/dialog/date-range-presets';
import { Item } from '@common/ui/forms/listbox/item';
import { Checkbox } from '@common/ui/forms/toggle/checkbox';
import { List, ListItem } from '@common/ui/list/list';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { currencyFormat } from '@common/utils/string/money-format';
import { AnimatePresence } from 'framer-motion';
import React, {Fragment, ReactNode, useState} from 'react';
import { CreatePayNowDialog } from '../billings/paynow-dialog';
import { useBillingUsage } from '@app_modules/requests/use-billing-usage';
import { Link } from 'react-router-dom';


export function AccountBillingPage() {
  const {data: usage} = useBillingUsage();
  const {trans} = useTrans();
  const {user} = useAuth();
  const query = useProjects({userId: user!.id, paginate: 'lengthAware'});
  const {sortDescriptor, setSortDescriptor, searchQuery, setSearchQuery} =
    query;
  const sortValue = `${sortDescriptor.orderBy}|${sortDescriptor.orderDir}`;
  const pending = usage?.billing.pending;
  const available = usage?.billing.available;

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Billing" />
      </StaticPageTitle>
      <div className="flex h-screen flex-col overflow-y-scroll">
        <DashboardNavbar />
        <div className="relative">
          <div className="mx-auto flex-auto px-12">
            <AdHost slot="dashboard_top" className="mb-36" />
            <AnimatePresence initial={false} mode="wait">
              <div className="min-h-full gap-12 overflow-x-hidden p-12 md:gap-24 md:p-24">
                <div className="mb-24 items-center justify-between gap-24 md:flex">
                  <StaticPageTitle><Trans message="Billing" /></StaticPageTitle>
                  <h1 className="mb-24 text-3xl font-light md:mb-0"><Trans message="Billing" /></h1>
                </div>
                <div className="flex flex-shrink-0 items-center overflow-x-auto gap-12 md:gap-24 mb-12 md:mb-24">
                  <div className="rounded-panel flex h-full flex-auto items-center gap-18 whitespace-nowrap p-20">
                    <div className="flex-auto">
                      <Accordion  expandedValues={[0]} defaultExpandedValues={[0]} variant="outline" className="mb-10 mt-20">
                          <AccordionItem
                            mode='single'
                            chevronPosition="left"
                            labelClassName='text-lg font-bold text-main'
                            label={<Trans message="Account Balance" />}
                          >
                            <List padding="p-0" className='scroll-p-8 overflow-y-scroll h-200 min-h-200 bg-gray-300'>
                              <ListItem padding="px-4 py-10">
                                <div className='h-48 rounded-panel border-b-2 shadow py-4 px-2'>
                                  <span className=' inline-block align-middle'>
                                  <Trans message="Pending" />
                                  </span>
                                  <span className='text-lg font-bold float-right'>
                                    {currencyFormat(pending)}
                                  </span>
                                </div>
                              </ListItem>

                              <ListItem padding="px-4 py-10">
                                <div className='h-48 rounded-panel border-b-2 shadow py-4 px-2'>
                                  <span className=' inline-block align-middle'>
                                  <Trans message="Available Balance" />
                                  </span>
                                  <span className='text-lg font-bold float-right'>
                                    {currencyFormat(available)}
                                  </span>
                                </div>
                              </ListItem>
                              
                              <section className=" w-full rounded-panel bg">
                                <div className="flex justify-center pt-10">
                                  <CreatePayNowButton/>
                                </div>
                              </section>
                              
                            </List>
                          </AccordionItem>
                      </Accordion>
                    </div>
                  </div>

                  <div className="rounded-panel flex h-full flex-auto items-center gap-18 whitespace-nowrap p-20">
                    <div className="flex-auto">
                      <Accordion  expandedValues={[0]} defaultExpandedValues={[0]} variant="outline" className="mb-10 mt-20">
                          <AccordionItem
                            mode='single'
                            chevronPosition="left"
                            labelClassName='text-lg font-bold text-main'
                            label={<Trans message="Payment Method" />}
                          >
                            <List padding="p-0" className='scroll-p-8 overflow-y-scroll h-200 min-h-200 bg-gray-300'>
                              <ListItem padding="px-4 py-10">
                                <div className='h-48 rounded-panel border-b-2 shadow py-4 px-2'>
                                  <span className=' inline-block align-middle'>
                                  <Trans message="Primary Method" />
                                  </span>
                                  <span className='text-lg font-bold float-right'>
                                  </span>
                                </div>
                              </ListItem>

                              
                              
                            </List>
                          </AccordionItem>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </AnimatePresence>
            <AdHost slot="dashboard_bottom" className="mt-36" />
          </div>
        </div>
        <Footer className="mt-40 px-40" />
      </div>
    </Fragment>
  );
}


function CreatePayNowButton() {
  return (
    <Button
      variant="outline"
      size="xs"
      color="primary"
      elementType={Link}
      to="/billings/make-payment"
    >
      <Trans message="Make Payment" />
    </Button>
  );
}
