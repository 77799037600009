import {useMutation} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {apiClient, queryClient} from '@common/http/query-client';
import {TeamQueryKeys} from './team-query-keys';
import {message} from '@common/i18n/message';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';
import { useActiveTeamId } from '@app_modules/teams/active-team-id-context';
import { PersonalTeam } from '@app_modules/teams/user-teams';

interface Response extends BackendResponse {}

export interface DeleteTeamPayload {
  id: number;
}

function deleteTeam({id}: DeleteTeamPayload): Promise<Response> {
  return apiClient.delete(`team/${id}`).then(r => r.data);
}

export function useDeleteTeam() {
  const {teamId, setTeamId} = useActiveTeamId();
  return useMutation({
    mutationFn: (props: DeleteTeamPayload) => deleteTeam(props),
    onSuccess: (r, payload) => {
      toast(message('Deleted team'));
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.fetchTeams,
      });
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.fetchUserTeams,
      });
      queryClient.invalidateQueries({
        queryKey: TeamQueryKeys.teamWithMembers(payload.id),
      });

      // if user deleted team that is currently active, switch to personal team
      if (teamId === payload.id) {
        setTeamId(PersonalTeam.id);
      }
    },
    onError: err => showHttpErrorToast(err),
  });
}
