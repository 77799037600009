import { useActiveTeamId } from './teams/active-team-id-context';
import {Team} from './teams/team';
import {
  useInfiniteData,
  UseInfiniteDataProps,
} from '@common/ui/infinite-scroll/use-infinite-data';

interface Params {
  userId?: string | number;
  published?: string;
  paginate?: UseInfiniteDataProps<undefined>['paginate'];
}

export function useTeams(params: Params) {
  const {teamId} = useActiveTeamId();
  return useInfiniteData<Team>({
    queryKey: ['teams', {...params, teamId}],
    endpoint: 'teams',
    paginate: params.paginate || 'simple',
    defaultOrderBy: 'updated_at',
    defaultOrderDir: 'desc',
    queryParams: {
      ...params,
      teamId,
    },
    willSortOrFilter: true,
  });
}
